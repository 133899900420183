<template>
  <div v-show="showLoader" class="no-js-hidden loader__container">
      <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
          <circle cx="170" cy="170" r="160" stroke="black"/>
          <circle cx="170" cy="170" r="135" stroke="red"/>
          <circle cx="170" cy="170" r="110" stroke="orange"/>
          <circle cx="170" cy="170" r="85" stroke="purple"/>
      </svg>
    </div>
</template>

<script>
import state from '../state';

export default {
  data() {
    return  state;
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

@keyframes preloader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loader__container {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .5);
}

.loader {
    max-width: 10rem;
    width: 100%;
    height: auto;
    stroke-linecap: round;
}

circle {
    fill: none;
    stroke-width: 10;
    animation-name: preloader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 170px 170px;
    will-change: transform;

    &:nth-of-type(1) {
        stroke-dasharray: 550;
    }

    &:nth-of-type(2) {
        stroke-dasharray: 500;
    }

    &:nth-of-type(3) {
        stroke-dasharray: 450;
    }

    &:nth-of-type(4) {
        stroke-dasharray: 300;
    }

    @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
            // animation-delay: -#{$i * 0.25}s;
            animation-duration: #{$i * 1}s;
        }
    }
}

circle:first-child {
    stroke: $accent;
}

</style>
