var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { method: "post", "accept-charset": "UTF-8" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handler($event)
        }
      }
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: _vm.csrfTokenName },
        domProps: { value: _vm.csrfTokenValue }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "action", value: "gmcontact/front" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gm-form__field", staticStyle: { display: "none" } },
        [
          _c("label", { attrs: { for: "monsuperparam" } }, [
            _vm._v(_vm._s(_vm.trans["contact.robot"]))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.monsuperparam,
                expression: "monsuperparam"
              }
            ],
            attrs: { id: "monsuperparam", name: "monsuperparam", type: "text" },
            domProps: { value: _vm.monsuperparam },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.monsuperparam = $event.target.value
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gm-form__field" }, [
        _c("label", { attrs: { for: "email" } }, [
          _vm._v(_vm._s(_vm.trans["contact.email"]))
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          class: { "form__input--error": _vm.errors.email.length > 0 },
          attrs: {
            id: "email",
            type: "email",
            name: "email",
            autocapitalize: "off"
          },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gm-form__error" },
          [
            _c("errors-form", {
              staticClass: "no-js-hidden gm-form__errors",
              attrs: {
                "aria-hidden": "true",
                name: "email",
                errors: _vm.errors
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gm-form__field" }, [
        _c("label", { attrs: { for: "message" } }, [
          _vm._v(_vm._s(_vm.trans["contact.message"]))
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.message,
              expression: "message"
            }
          ],
          class: { "form__input--error": _vm.errors.message.length > 0 },
          attrs: { id: "message", name: "message" },
          domProps: { value: _vm.message },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.message = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gm-form__error" },
          [
            _c("errors-form", {
              staticClass: "no-js-hidden gm-form__errors",
              attrs: {
                "aria-hidden": "true",
                name: "message",
                errors: _vm.errors
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gm-form__action" }, [
        _c("button", { staticClass: "btn gm-btn", attrs: { type: "submit" } }, [
          _vm._v("\n        " + _vm._s(_vm.trans["contact.send"]) + "\n    ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gm-form__error" },
          [
            _c("errors-form", {
              staticClass: "no-js-hidden gm-form__errors",
              attrs: {
                "aria-hidden": "true",
                name: "global",
                errors: _vm.errors
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-64b6f108", { render: render, staticRenderFns: staticRenderFns })
  }
}