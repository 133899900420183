/* eslint-disable */
// google maps
export default {
  "key": "googlemaps",
  "type": "api",
  "name": "Google Maps",
  "uri": "http://www.google.com/ads/preferences/",
  "needConsent": true,
  "cookies": [],
  "js": function () {
    "use strict";
    var mapOptions,
      map,
      uniqIds = [],
      i;

    if (tarteaucitron.user.mapscallback === undefined) {
      tarteaucitron.user.mapscallback = 'tac_googlemaps_callback';
    }

    tarteaucitron.addScript('//maps.googleapis.com/maps/api/js?v=3.exp&key=' + tarteaucitron.user.googlemapsKey + '&callback=' + tarteaucitron.user.mapscallback);

    window.tac_googlemaps_callback = function () {
      tarteaucitron.fallback(['googlemaps-canvas'], function (x) {
        var uniqId = '_' + Math.random().toString(36).substr(2, 9);
        uniqIds.push(uniqId);
        return '<div id="' + uniqId + '" zoom="' + x.getAttribute('zoom') + '" latitude="' + x.getAttribute('latitude') + '" longitude="' + x.getAttribute('longitude') + '" style="width:' + x.offsetWidth + 'px;height:' + x.offsetHeight + 'px"></div>';
      });

      for (i = 0; i < uniqIds.length; i += 1) {
        mapOptions = {
          zoom: parseInt(document.getElementById(uniqIds[i]).getAttribute('zoom'), 10),
          center: new google.maps.LatLng(parseFloat(document.getElementById(uniqIds[i]).getAttribute('latitude'), 10), parseFloat(document.getElementById(uniqIds[i]).getAttribute('longitude'), 10))
        };
        map = new google.maps.Map(document.getElementById(uniqIds[i]), mapOptions);
      }
    };
  },
  "fallback": function () {
    "use strict";
    var id = 'googlemaps';
    tarteaucitron.fallback(['googlemaps-canvas'], tarteaucitron.engage(id));
  }
};
