<template>
  <ul v-if="errors[name]">
      <li v-for="(error, index) in errors[name]" :key="index">
        {{ error }}
      </li>
    </ul>
</template>

<script>
export default {
  props: ['name', 'errors']
}
</script>
