<template>
  <div v-show="showAlert" @click.stop.prevent="closeAlert" class="no-js-hidden popup__alert">
    <div class="popup__alert--content">
      <header>
        <a @click.prevent="closeAlert" class="popup__alert--close"></a>
      </header>
      <section>
      <div v-html="alertMessage"></div>
      </section>
      <footer>
        <button @click.prevent="closeAlert" class="btn popup__alert--btn">{{ alertLabelButton }}</button>
      </footer>
    </div>
  </div>
</template>

<script>
import blockScroll from '../blockScroll';
import state from '../state';

export default {
  data() {
    return  state;
  },
  computed: {
    // block scroll on show alert
    blockScroll() {
      return blockScroll(this.showAlert);
    }
  },
  methods: {
    closeAlert() {
      this.showAlert = false;
      this.alertMessage = '';
      this.alertLabelButton = 'OK';
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../scss/variables';

.popup__alert {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .3);
    z-index: 99999;
    -webkit-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.popup__alert--content {
    display: flex;
    flex-direction: column;
    width: 40vw;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, .2), 0 40px 77px 0 rgba(0, 0, 0, .22);
    border-radius: $border-radius;

    @media (max-width: $medium) {
        width: 70vw;
    }

    @media (max-width: $small) {
        width: 90vw;
    }

    header {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
        border-bottom: 1px solid $gray-400;
    }

    section {
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        min-height: 100px;
    }

    footer {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid $gray-400;
    }
}

.popup__alert--close {
    display: flex;
    justify-content: flex-end;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &::after {
        content: '';
        width: 3.2rem;
        height: 3.2rem;
        background: url('../../icons/close.svg') no-repeat center;
    }
}

.popup__alert--btn {
    background-color: $accent;
    font-weight: bold;
}

</style>
