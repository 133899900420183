/* eslint-disable */
// generic iframe
export default {
  "key": "iframe",
  "type": "other",
  "name": "Web content",
  "uri": "",
  "needConsent": true,
  "cookies": [],
  "js": function () {
    "use strict";
    tarteaucitron.fallback(['tac_iframe'], function (x) {
      var width = x.getAttribute("width"),
        height = x.getAttribute("height"),
        url = x.getAttribute("data-url");

      return '<iframe src="' + url + '" width="' + width + '" height="' + height + '" frameborder="0" scrolling="no" allowtransparency allowfullscreen></iframe>';
    });
  },
  "fallback": function () {
    "use strict";
    var id = 'iframe';
    tarteaucitron.fallback(['tac_iframe'], function (elem) {
      elem.style.width = elem.getAttribute('width') + 'px';
      elem.style.height = elem.getAttribute('height') + 'px';
      return tarteaucitron.engage(id);
    });
  }
};
