var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showLoader,
          expression: "showLoader"
        }
      ],
      staticClass: "no-js-hidden loader__container"
    },
    [
      _c(
        "svg",
        {
          staticClass: "loader",
          attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 340 340" }
        },
        [
          _c("circle", {
            attrs: { cx: "170", cy: "170", r: "160", stroke: "black" }
          }),
          _vm._v(" "),
          _c("circle", {
            attrs: { cx: "170", cy: "170", r: "135", stroke: "red" }
          }),
          _vm._v(" "),
          _c("circle", {
            attrs: { cx: "170", cy: "170", r: "110", stroke: "orange" }
          }),
          _vm._v(" "),
          _c("circle", {
            attrs: { cx: "170", cy: "170", r: "85", stroke: "purple" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b77f3b82", { render: render, staticRenderFns: staticRenderFns })
  }
}