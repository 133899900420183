var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showAlert,
          expression: "showAlert"
        }
      ],
      staticClass: "no-js-hidden popup__alert",
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.closeAlert($event)
        }
      }
    },
    [
      _c("div", { staticClass: "popup__alert--content" }, [
        _c("header", [
          _c("a", {
            staticClass: "popup__alert--close",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.closeAlert($event)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("section", [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.alertMessage) } })
        ]),
        _vm._v(" "),
        _c("footer", [
          _c(
            "button",
            {
              staticClass: "btn popup__alert--btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.closeAlert($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.alertLabelButton))]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b5860b40", { render: render, staticRenderFns: staticRenderFns })
  }
}