/* eslint-disable */
/*global tarteaucitron */
export default {
  "adblock": "Bonjour! Ce site joue la transparence et vous donne le choix des services tiers à activer.",
  "adblock_call": "Merci de désactiver votre adblocker pour commencer la personnalisation.",
  "reload": "Recharger la page",

  "alertBigScroll": "En continuant de défiler,",
  "alertBigClick": "En poursuivant votre navigation,",
  "alertBig": "vous acceptez l'utilisation de services tiers pouvant installer des cookies",

  "alertBigPrivacy": "Ce site utilise des cookies et vous donne le contrôle sur ce que vous souhaitez activer",
  "alertSmall": "Gestion des services",
  "acceptAll": "OK, tout accepter",
  "personalize": "Personnaliser",
  "close": "Fermer",

  "all": "Préférence pour tous les services",

  "info": "Protection de votre vie privée",
  "disclaimer": "En autorisant ces services tiers, vous acceptez le dépôt et la lecture de cookies et l'utilisation de technologies de suivi nécessaires à leur bon fonctionnement.",
  "allow": "Autoriser",
  "deny": "Interdire",
  "noCookie": "Ce service ne dépose aucun cookie.",
  "useCookie": "Ce service peut déposer",
  "useCookieCurrent": "Ce service a déposé",
  "useNoCookie": "Ce service n'a déposé aucun cookie.",
  "more": "En savoir plus",
  "source": "Voir le site officiel",
  "credit": "Gestion des cookies par tarteaucitron.js",

  "fallback": "est désactivé.",

  "ads": {
    "title": "Régies publicitaires",
    "details": "Les régies publicitaires permettent de générer des revenus en commercialisant les espaces publicitaires du site."
  },
  "analytic": {
    "title": "Mesure d'audience",
    "details": "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles à l'amélioration du site."
  },
  "social": {
    "title": "Réseaux sociaux",
    "details": "Les réseaux sociaux permettent d'améliorer la convivialité du site et aident à sa promotion via les partages."
  },
  "video": {
    "title": "Vidéos",
    "details": "Les services de partage de vidéo permettent d'enrichir le site de contenu multimédia et augmentent sa visibilité."
  },
  "comment": {
    "title": "Commentaires",
    "details": "Les gestionnaires de commentaires facilitent le dépôt de vos commentaires et luttent contre le spam."
  },
  "support": {
    "title": "Support",
    "details": "Les services de support vous permettent d'entrer en contact avec l'équipe du site et d'aider à son amélioration."
  },
  "api": {
    "title": "APIs",
    "details": "Les APIs permettent de charger des scripts : géolocalisation, moteurs de recherche, traductions, ..."
  },
  "other": {
    "title": "Autre",
    "details": "Services visant à afficher du contenu web."
  }
};
