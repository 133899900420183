import Vue from 'vue';
// import LazyLoad from 'vanilla-lazyload';
import inView from 'in-view';
import 'url-search-params-polyfill';
import 'nodelist-foreach-polyfill';
import leaflet from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
// preload css async
import 'fg-loadcss/src/cssrelpreload';
// import './js/search';
import nav from './js/nav';
import Loader from './js/Components/Loader.vue';
import Alert from './js/Components/Alert.vue';
import ContactForm from './js/Components/ContactForm.vue';
import tarteaucitron from './js/tarteaucitron/tarteaucitron';

require('es6-promise').polyfill();
require('./critical.scss');
require('./index.scss');

Vue.config.productionTip = false;

// remove no js on load
document.body.classList.remove('no-js');

// init lazy load
// eslint-disable-next-line
// const myLazyLoad = new LazyLoad();

// init menu
nav();

// loader
const loaderContainer = document.getElementById('loader');
if (loaderContainer) {
  // eslint-disable-next-line
  const initLoader = new Vue({
    el: document.getElementById('loader'),
    template: '<Loader/>',
    components: {
      Loader
    }
  })
}

// popup alert
const popup = document.getElementById('popup--alert');
if (popup) {
  // eslint-disable-next-line
  const initAlert = new Vue({
    el: popup,
    template: '<Alert/>',
    components: {
      Alert
    }
  })
}
// form contact
const contactForm = document.getElementById('gm-contact__form');
if (contactForm) {
  // eslint-disable-next-line
  const initContact = new Vue({
    el: contactForm,
    template: '<ContactForm/>',
    components: {
      ContactForm
    }
  })
}

const effect = document.querySelectorAll('.gm-effect');
if (effect.length > 0) {
  inView('.gm-effect')
    .on('enter', el => {
      if (el.classList.contains('gm-effect-inner') === false) {
        el.classList.add('gm-effect-inner');
      }
    });
  // .on('exit', el => {
  //     el.style.opacity = 0.5;
  // });
}

const leafMap = document.getElementById('leaf-map');
if (leafMap) {
  if (typeof window.startMap === "function") {
    leaflet.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    window.startMap(leaflet);
  }
}

const openCookie = document.querySelectorAll('.gm-open-cookie');
openCookie.forEach(element => {
  element.addEventListener('click', (e) => {
    e.preventDefault();
    if (window.tarteaucitron !== undefined) {
      window.tarteaucitron.userInterface.openPanel();
    }
  });
});

if (process.env.NODE_ENV === 'production') {

  if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/assets/service-worker.js');
    });
  }

}

tarteaucitron.init({
  "hashtag": "#tarteaucitron",
  "highPrivacy": false,
  "orientation": "bottom",
  "adblocker": false,
  "showAlertSmall": false,
  "cookieslist": true,
  "removeCredit": true
});


// dev
if (module.hot) {
  module.hot.accept();
}
