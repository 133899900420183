<template>
<form v-on:submit.prevent="handler" method="post" accept-charset="UTF-8">
  <input type="hidden" :name="csrfTokenName" :value="csrfTokenValue">
  <input type="hidden" name="action" value="gmcontact/front">
  <div class="gm-form__field" style="display: none;">
      <label for="monsuperparam">{{ trans['contact.robot'] }}</label>
      <input v-model="monsuperparam" id="monsuperparam" name="monsuperparam" type="text">
  </div>
  <div class="gm-form__field">
    <label for="email">{{ trans['contact.email'] }}</label>
    <input v-bind:class="{ 'form__input--error': (errors.email.length > 0)}" v-model="email" id="email" type="email" name="email" autocapitalize=off />
    <div class="gm-form__error">
        <errors-form aria-hidden="true" class="no-js-hidden gm-form__errors" name="email" v-bind:errors="errors"></errors-form>
    </div>
  </div>
  <div class="gm-form__field">
    <label for="message">{{ trans['contact.message'] }}</label>
    <textarea v-model="message" v-bind:class="{ 'form__input--error': (errors.message.length > 0)}" id="message" name="message"></textarea>
    <div class="gm-form__error">
      <errors-form aria-hidden="true" class="no-js-hidden gm-form__errors" name="message" v-bind:errors="errors"></errors-form>
    </div>
  </div>

  <div class="gm-form__action">
    <button type="submit" class="btn gm-btn" >
        {{ trans['contact.send'] }}
    </button>
    <div class="gm-form__error">
        <errors-form aria-hidden="true" class="no-js-hidden gm-form__errors" name="global" v-bind:errors="errors"></errors-form>
    </div>
  </div>
</form>
</template>

<script>
import axios from 'axios';
import isEmail from 'validator/lib/isEmail';
import state from '../state';
import ErrorsForm from './ErrorsForm';

// set default state errors on init and after send
const defaultErrors = {
  name: [],
  email: [],
  message: [],
};

export default {
  components: { ErrorsForm },
  data() {
    return  {
      csrfTokenValue: '',
      csrfTokenName: '',
      monsuperparam: '',
      email: '',
      message: '',
      errors: defaultErrors,
      state,
      trans: {}
    }
  },
  mounted() {
    this.csrfTokenValue = window.csrfTokenValue;
    this.csrfTokenName = window.csrfTokenName;
    this.trans = window.trans;
  },
  methods: {
    // verif if form is complete
    verifForm() {
      let back = false;
      this.errors.email = [];
      this.errors.message = [];
      // email
      if (this.email === '') {
        this.errors.email.push(this.trans['contact.error.email.empty']);
        back = true;
      } else if (!isEmail(this.email)) {
        this.errors.email.push(this.trans['contact.error.email.error']);
        back = true;
      }

      if (this.message === '') {
        this.errors.message.push(this.trans['contact.error.message.empty']);
        back = true;
      }
      return back;
    },
    // reset all form value
    reset() {
      this.errors = defaultErrors;
      this.email = '';
      this.message = '';
      this.monsuperparam = '';
    },
    handler(event) {
      // if form is ok
      if (this.verifForm() === false) {
        this.state.showLoader = true;
        // send at the same url of page, action determine plugin handler
        const url = window.location.href;
        const params = new URLSearchParams();
        // security
        params.append(window.csrfTokenName, window.csrfTokenValue);
        // call plugin action
        params.append('action', event.target.elements.action.value);
        params.append('email', this.email);
        params.append('monsuperparam', this.monsuperparam);
        params.append('message', this.message);

        axios
          .post(url, params)
          .then(e => {
            if (e.status === 200) {
              const { data } = e;
              this.state.showLoader = false;
              if (data.errors !== false) {
                this.errors = Object.assign({}, defaultErrors, data.errors);
              }
              if (data.success !== undefined && data.success === true) {
                this.state.showAlert = true;
                this.state.alertMessage = this.trans['contact.success'];
                this.reset();
              }
            }
          })
          .catch(e => {
            this.state.showLoader = false;
            console.log(e); // eslint-disable-line
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

</style>
