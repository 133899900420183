import blockScroll from './blockScroll';

export default () => {
  // burgen button nav
  const toggleNav = () => {
    // Define targets by their class or id
    const button = document.querySelector('.nav-button');
    const target = document.querySelector('nav[role="navigation"]');
    // click-touch event
    if (button) {
      button.addEventListener(
        'click',
        e => {
          button.classList.toggle('is-active');
          target.classList.toggle('is-opened');
          blockScroll(target.classList.contains('is-opened'));
          e.preventDefault();
        },
        false
      );
    }
  }; // end toggleNav()
  toggleNav();

  // the nav
  const nav = document.querySelector('nav[role="navigation"]');
  const navPosition = window.getComputedStyle(nav, null).getPropertyValue('position');
  // if nav is absolute == is mobile nav mode
  if (navPosition === 'fixed') {
    const collapse = document.querySelectorAll('nav > ul > li.has-child');
    collapse.forEach(e => {
      e.addEventListener('click', event => {
        const target = event.currentTarget;
        target.classList.toggle('is-opened');
      });
    });
  }
};
